<script>
import { mapActions, mapState } from "vuex";
import { defineComponent } from "vue";
import PanelMobileMenu from "@/components/PanelMobileMenu.vue";

export default defineComponent({
  name: "PanelLayout",
  components: { PanelMobileMenu },

  data() {
    return {
      show_profile_dropdown: false,
      show_mobile_menu: false,
      showMobileDrawer: false,
      showNotifications: false,

      navigation: [
        { name: "CoursesView", title: "Мои курсы" },
        { name: "TasksView", title: "Задания" },
        { name: "OnlineView", title: "Вебинары и встречи" },
        { name: "FinanceView", title: "Финансы" },
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.user,
      windowWidth: (state) => state.system.window_width,
    }),
  },

  watch: {
    $route() {
      this.show_profile_dropdown = false;
      this.showMobileDrawer = false;
    },
  },

  methods: {
    ...mapActions({
      loadProfileInformation: "user/loadProfileInformation",
    }),

    openProfileInfo() {
      this.show_profile_dropdown = true;
      this.showMobileDrawer = true;
    },

    hideProfileDropdown() {
      this.show_profile_dropdown = false;
    },

    logoutUser() {
      localStorage.removeItem("token");
      window.location.reload();
    },

    hideNotifications() {
      this.showNotifications = false;
    },
  },

  beforeMount() {
    this.loadProfileInformation().then(() => {
      if (!this.user.first_name || !this.user.last_name) {
        this.$router.push({
          name: "AuthView",
        });
      }
    });
  },
});
</script>

<template>
  <PanelMobileMenu
    v-if="show_mobile_menu"
    @close="show_mobile_menu = false"
    :navigation="navigation"
  />

  <template v-if="user">
    <header class="panel-header">
      <div class="container">
        <button class="burger-menu" @click="show_mobile_menu = true">
          <i class="icon icon-burger-menu" />
        </button>

        <RouterLink :to="{ name: 'HomeView' }">
          <img src="../../assets/images/logo-business.svg" alt="logotype" />
        </RouterLink>

        <nav>
          <router-link
            :to="{ name: item.name }"
            v-for="item in navigation"
            :key="item.name"
            class="header-button"
          >
            {{ item.title }}
          </router-link>
        </nav>

        <div class="panel-header__right">
          <div class="notifications" v-click-outside="hideNotifications">
            <button
              type="button"
              :class="{ active: showNotifications }"
              @click="showNotifications = !showNotifications"
            >
              <i class="icon icon-notification" />
            </button>

            <div class="notifications__list" v-if="showNotifications">
              <img src="@/assets/images/empty-notifications.svg" alt="" />
              <h3>Уведомлений пока нет</h3>
              <p>Если что-то случится, мы сразу вам сообщим!</p>
            </div>
          </div>

          <div class="header-profile" v-click-outside="hideProfileDropdown">
            <button @click="openProfileInfo" class="header-profile__avatar">
              <span
                :style="{
                  backgroundImage: `url(${user.media[0]?.url.full_size})`,
                }"
              >
                <i v-if="!user.media.length" class="icon icon-person" />
              </span>
            </button>

            <div
              class="header-profile__dropdown profile-dropdown"
              v-if="show_profile_dropdown && windowWidth > 767"
            >
              <div class="profile-dropdown__user">
                <div class="profile-dropdown__avatar">
                  <span
                    :style="{
                      backgroundImage: `url(${user.media[0]?.url.full_size})`,
                    }"
                  >
                    <i v-if="!user.media.length" class="icon icon-person" />
                  </span>
                </div>
                <div class="profile-dropdown__info">
                  <h6>{{ user.first_name }} {{ user.last_name }}</h6>
                  <router-link :to="{ name: 'FinanceView' }">
                    <i class="icon icon-money" /> {{ user.balance }} ₽
                  </router-link>
                </div>
              </div>
              <nav>
                <router-link :to="{ name: 'ProfileView' }">
                  <i class="icon icon-person" /> Личный профиль
                </router-link>

                <button @click="logoutUser">
                  <i class="icon icon-exit" />Выйти
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div
      class="profile-drawer"
      v-if="showMobileDrawer && windowWidth < 768"
      @click="showMobileDrawer = false"
    >
      <div class="profile-drawer__wrapper" @click.stop>
        <button type="button" @click="showMobileDrawer = false">
          <i class="icon icon-close" />
        </button>
        <div class="profile-dropdown__user">
          <div class="profile-dropdown__avatar">
            <span
              :style="{
                backgroundImage: `url(${user.media[0]?.url.full_size})`,
              }"
            >
              <i v-if="!user.media.length" class="icon icon-person" />
            </span>
          </div>
          <div class="profile-dropdown__info">
            <h6>{{ user.first_name }} {{ user.last_name }}</h6>
            <router-link :to="{ name: 'FinanceView' }">
              <i class="icon icon-money" /> 23 923,00 ₽
            </router-link>
          </div>
        </div>
        <nav>
          <router-link :to="{ name: 'ProfileView' }">
            <i class="icon icon-person" /> Личный профиль
          </router-link>

          <button @click="logoutUser"><i class="icon icon-exit" />Выйти</button>
        </nav>
      </div>
    </div>

    <router-view />
  </template>

  <Transition>
    <div class="panel-overlay" v-if="!user">
      <img src="../../assets/images/logo.svg" alt="logotype" />
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.panel-header {
  background-color: $background-light;
  height: 80px;
  border-bottom: 1px solid $stroke-fourth;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1023px) {
    height: 64px;
  }

  > .container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: $base-space * 15;
    position: relative;

    @media (max-width: 1023px) {
      gap: calc($base-space * 3);
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        width: 114px;

        @media (max-width: 1023px) {
          width: 97px;
        }
      }
    }

    > nav {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space * 8;

      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: $base-space * 3;
  }
}

.notifications {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: $background-light-accent;
    }
    > i {
      width: 28px;
      height: 28px;
      background-color: $text-dark-primary;
    }
  }

  &__list {
    position: absolute;
    top: calc(100% + 24px);
    right: 0;
    border-radius: 12px;
    background: $background-light;
    box-shadow: 0 0 16px 0 rgba(7, 6, 52, 0.08);
    padding: calc($base-space * 8) calc($base-space * 6);
    z-index: 3;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1023px) {
      padding: calc($base-space * 6) calc($base-space * 4);
      top: calc(100% + 16px);
    }

    @media (max-width: 767px) {
      position: fixed;
      left: 0;
      right: 0;
      top: 65px;
      bottom: 0;
      box-shadow: none;
      border-radius: 0;
    }

    > h3 {
      margin: calc($base-space * 4) 0 0;
      @include h6();
      color: $text-dark-primary;

      @media (max-width: 1023px) {
        margin: calc($base-space * 3) 0 0;
      }

      @media (max-width: 767px) {
        margin: calc($base-space * 6) 0 0;
      }
    }

    > p {
      white-space: nowrap;
      margin: $base-space 0 0;
      @include s-text-regular();
      color: $text-dark-second;
    }
  }
}

.header-profile {
  position: relative;

  &__avatar {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $stroke-fourth;

    &:hover {
      border: 3px solid $stroke-fourth;
    }

    > span {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: $background-ultralight-accent;
      display: flex;
      align-items: center;
      justify-content: center;

      > i {
        width: 24px;
        height: 24px;
        background-color: $text-accent;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    width: 320px;
    border-radius: 8px;
    @include ultralight-shadow();
    background-color: $background-light;
    z-index: 3;
    overflow: hidden;

    @media (max-width: 1023px) {
      top: calc(100% + 8px);
      width: 240px;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-in;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.profile-dropdown {
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  width: 320px;
  border-radius: 8px;
  @include ultralight-shadow();
  background-color: $background-light;
  z-index: 3;
  overflow: hidden;
  box-shadow: 0 0 16px 0 rgba(7, 6, 52, 0.08);

  @media (max-width: 1023px) {
    top: calc(100% + 12px);
  }

  &__user {
    padding: calc($base-space * 6);
    border-bottom: 1px solid $stroke-fourth;
    display: grid;
    grid-template-columns: 48px 1fr;
    align-items: center;
    gap: calc($base-space * 4);

    @media (max-width: 767px) {
      padding: calc($base-space * 6) calc($base-space * 5);
      grid-template-columns: 52px 1fr;
    }
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid $stroke-fourth;

    @media (max-width: 767px) {
      width: 52px;
      height: 52px;
    }

    > span {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: $background-ultralight-accent;
      display: flex;
      align-items: center;
      justify-content: center;

      > i {
        width: 24px;
        height: 24px;
        background-color: $text-accent;
      }
    }
  }

  &__info {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    gap: calc($base-space * 2);

    > h6 {
      @include m-text-regular();
      color: $text-dark-primary;
      @include max-stroke(1);

      @media (max-width: 767px) {
        @include h5();
        padding: 0 ($base-space * 8) 0 0;
      }
    }

    > a {
      border-radius: 20px;
      background: $background-light-accent;
      padding: calc($base-space / 2) calc($base-space * 2);
      @include caption-regular();
      color: $text-accent;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: calc($base-space / 2);

      @media (max-width: 767px) {
        padding: calc($base-space * 2) calc($base-space * 3);
      }

      &:hover {
        background-color: $background-accent;
      }

      > i {
        width: 20px;
        height: 20px;
        background-color: $text-accent;

        @media (max-width: 767px) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  > nav {
    padding: calc($base-space * 2) 0;

    > a,
    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space * 2;
      @include m-text-regular();
      padding: ($base-space * 3) ($base-space * 6);

      &:hover {
        background-color: $background-ultralight-accent;
      }

      > i {
        width: 24px;
        height: 24px;
      }
    }

    > a {
      color: $text-dark-primary;

      > i {
        background-color: $text-dark-primary;
      }
    }

    > button {
      color: $text-error;

      > i {
        background-color: $text-error;
      }
    }
  }
}

.profile-drawer {
  background: $background-main-overlay;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &__wrapper {
    background: $background-light;
    position: relative;
    border-radius: 16px 16px 0 0;
    max-height: 100vh;
    overflow-y: auto;
    overscroll-behavior: contain;
    width: 100%;

    > button {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background: none;
      border: none;
      position: absolute;
      top: 16px;
      right: 16px;

      > i {
        width: 32px;
        height: 32px;
        background: $text-dark-third;
      }
    }

    > nav {
      padding: calc($base-space * 4) calc($base-space * 5) calc($base-space * 6);

      > a,
      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: $base-space * 2;
        @include m-text-regular();
        padding: ($base-space * 2.5) 0;

        &:hover {
          background-color: $background-ultralight-accent;
        }

        > i {
          width: 24px;
          height: 24px;
        }
      }

      > a {
        color: $text-dark-primary;

        > i {
          background-color: $text-dark-primary;
        }
      }

      > button {
        color: $text-error;

        > i {
          background-color: $text-error;
        }
      }
    }
  }
}
</style>
