import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vClickOutside from "click-outside-vue3";
import Popper from "vue3-popper";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import * as MathLive from "mathlive";
import VueLatex from "vatex";

import "./scss/app.scss";

createApp(App)
  .use(store)
  .use(router)
  .use(vClickOutside)
  .use(MathLive)
  .use(VueLatex)
  .component("Popper", Popper)
  .component("VueDatePicker", VueDatePicker)
  .mount("#app");
